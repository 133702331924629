<template>
  <div>
    <v-container fluid class="min-height">
      <div>
        <v-row align="center" justify="center">
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="animate__animated animate__fadeInLeft animate__faster"
          >
            <v-card class="pa-2 pb-4">
              <v-card-title>
                <v-icon class="mr-2">mdi-medical-bag</v-icon>
                Recetas Vigentes
              </v-card-title>
              <div v-if="isLoading" class="pa-12">
                <v-row align="center" justify="center">
                  <v-progress-circular
                    :size="86"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
                <v-row align="center" justify="center">
                  <v-card-title class="primary--text">Cargando...</v-card-title>
                </v-row>
              </div>
              <v-card-text v-if="!isLoading">
                <v-list v-if="medicamentos.length > 0">
                  <v-row align="stretch" justify="start">
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                      v-for="(recipe, i) in medicamentos"
                      :key="i"
                    >
                      <v-card rounded dark color="accent">
                        <v-card-title>
                          <v-icon class="mr-1">mdi-pill</v-icon>
                          {{ recipe.name }}
                        </v-card-title>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-list>
                <p v-else>No se han encontrado recetas registradas.</p>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  to="Recetas"
                  color="primary"
                  rounded
                  :disabled="isLoading"
                >
                  ir a mis recetas
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            class="animate__animated animate__fadeInLeft animate__faster"
            v-if="!isLoading"
          >
            <Calendar :medicamentos="medicamentos" />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "../plugins/axios";

export default {
  name: "Home",
  data: () => ({
    isLoading: true,
    medicamentos: [],
  }),
  components: {
    Calendar: () => import("../components/shared/Calendar"),
  },
  mounted() {
    this.getAllRecipes();
  },
  methods: {
    getAllRecipes() {
      this.isLoading = true;
      const userId = sessionStorage.getItem("userId");
      axios
        .get(`recipes/user/${userId}`)
        .then((response) => {
          const recipes = response.data.data;
          if (recipes?.length > 0) {
            this.medicamentos = recipes;
          }
          setTimeout(() => (this.isLoading = false), 2000);
        })
        .catch((error) => {
          if (error) {
            this.$toast.error(
              "Ha ocurrido un error mientras se obtenian las recetas médicas."
            );
          }
          setTimeout(() => (this.isLoading = false), 2000);
        });
    },
  },
};
</script>
